import React from "react";
import { Dropdown, Icon, palette } from "@stats/playbook-components";
import { Link, useHistory } from "react-router-dom";
import { useClientsProvider } from "../../providers/ClientsProvider";
import styled from "styled-components";
import { useProductVerticalsProvider } from "../../providers/ProductVerticalsProvider";
import NavigationOptions from "./NavigationOptions";
import { flattenProducts } from "../../utils";

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  color: ${palette["sp-white"]};
  margin-bottom: 28px;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 12px;
  font-weight: bold;
`;

const StyledDropdown = styled(Dropdown)`
  width: 272px;
`;

type ClientPageSideProps = {
  clientHash: string;
};

const ClientPageSide: React.FC<ClientPageSideProps> = ({ clientHash }) => {
  const history = useHistory();
  const { allClients } = useClientsProvider();
  const { totalProducts } = useProductVerticalsProvider();
  const allProducts = flattenProducts(totalProducts);
  const selectedClient = allClients
    ? allClients.find((c) => c.clientId === clientHash.toString())
    : undefined;

  const onItemSelect = (newClientId: string): void => {
    history.push(`/clients/${newClientId}`);
  };

  return (
    <>
      <StyledLink to={"/clients"}>
        <Icon variant={"chevron-left"} fill={"sp-white"} />{" "}
        <span style={{ margin: "0 0 0 8px" }}>Back to catalogue</span>
      </StyledLink>
      <StyledDropdown
        menuItems={
          allClients
            ? allClients.map((client) => {
                return {
                  value: client.clientId,
                  name: `${client.organizationName} (${client.clientId})`,
                };
              })
            : []
        }
        includeSearch
        value={
          selectedClient &&
          `${selectedClient.organizationName} (${selectedClient.clientId})`
        }
        onItemSelect={onItemSelect}
      />
      <NavigationOptions products={allProducts} clientHash={clientHash} />
    </>
  );
};

export default ClientPageSide;
