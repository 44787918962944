import React, {useState} from "react";
import {
  Icon,
  Table,
  TBody,
  TD,
  TH,
  THead,
  Tile,
  TR,
} from "@stats/playbook-components";
import {Group} from "../ClientPage/Groups/GroupModal";
import {Product} from "../../types";
import {Switch} from "../Switch";
import styled from "styled-components";
import {useProductVerticalsProvider} from "../../providers/ProductVerticalsProvider";
import {flattenProducts} from "../../utils";
import EditGroupPermissionsModal from "./EditGroupPermissionsModal";

const SwitchWrapper = styled.div`
  display: flex;
`;

const StyledEditIconColumn = styled(TD)`
  width: 32px;
  cursor: pointer;
`

type GroupApplicationsTileProps = {
  selectedGroup: Group;
};

const GroupApplicationsTile: React.FC<GroupApplicationsTileProps> = ({
                                                                       selectedGroup,
                                                                     }) => {
  const [roles, setRoles] = useState<string[]>(selectedGroup.products);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<Product>();
  const [isProductEnabled, setIsProductEnabled] = useState<boolean>(false);

  const {totalProducts} = useProductVerticalsProvider();
  const allProducts = flattenProducts(totalProducts);
  const toggleRole = (role: string): void => {
    if (roles?.includes(role)) {
      setRoles(roles.filter((x) => x !== role));
    } else {
      setRoles(roles.concat([role]));
    }
  };

  return (
    <Tile title={"Applications"}>
      <Table>
        <THead>
          <TR>
            <TH>{""}</TH>
            <TH>{"Application"}</TH>
            <TH>{""}</TH>
          </TR>
        </THead>
        <TBody>
          {allProducts.map((product: Product, index) => {
            return (
              <TR key={index}>
                <TD>
                  <SwitchWrapper>
                    <Switch
                      onClick={(): void => toggleRole(product.primaryRoleId)}
                      checked={roles.includes(product.primaryRoleId)}
                    />
                  </SwitchWrapper>
                </TD>
                <TD>
                  <span>{product.name}</span>
                </TD>
                <StyledEditIconColumn>
                  <Icon
                    variant={'edit'}
                    onClick={(): void => {
                      setIsModalOpen(true);
                      setSelectedProduct(product);
                      setIsProductEnabled(roles.includes(product.primaryRoleId))
                    }}
                  />
                </StyledEditIconColumn>
              </TR>
            );
          })}
          {
            selectedProduct && selectedGroup.groupId &&
            <EditGroupPermissionsModal
              groupId={selectedGroup.groupId}
              organizationId={selectedGroup.clientHash}
              selectedProduct={selectedProduct}
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              isProductEnabled={isProductEnabled}
              setIsProductEnabled={setIsProductEnabled}
            />
          }
        </TBody>
      </Table>
    </Tile>
  );
};

export default GroupApplicationsTile;
