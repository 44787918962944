import { Role, User } from "./AddUser/types";
import { ClientProductTournamentCalendar } from "./ClientPage/types";
import { trackPromise } from "react-promise-tracker";
import {InternalUserDetailValues} from "../types/UserProfileTypes";
import {productIdToRoleNameFilterMap} from "./utils";
import { RolePermission } from "./ProductPage/ProductPage.types";

export const baseURL =
  "https://mfnn9i9xne.execute-api.us-east-1.amazonaws.com/v0/";

const getApiGatewayId = (): string => {
  const currentEnv = process.env.REACT_APP_ENVIRONMENT || "staging";
  const apiGatewayIds = {
    staging: "mfnn9i9xne",
    production: "8t4g379fph",
  } as Record<string, string>;
  return apiGatewayIds[currentEnv] || "mfnn9i9xne";
};

export const APIS = {
  AUTH0: `https://${getApiGatewayId()}.execute-api.us-east-1.amazonaws.com/v0/routing`,
  CLIENTS: `https://${getApiGatewayId()}.execute-api.us-east-1.amazonaws.com/v0/clients`,
};

export declare interface Options extends RequestInit {
  ignoreCache?: boolean;
  redirect_uri?: string;
  audience: string;
  scope?: string;
  timeoutInSeconds?: number;
}

export const AccessTokenOptions: Options = {
  audience: `https://${getApiGatewayId()}.execute-api.us-east-1.amazonaws.com/`,
  scope: "access:user-management",
};

export const createNewUser = async ({
  email,
  name,
  clientHash,
  organizationName,
  accessToken,
  internalUserDetails
}: {
  email: string;
  name: string;
  clientHash: string;
  organizationName: string;
  accessToken: string;
  internalUserDetails?: InternalUserDetailValues;
}): Promise<Response> => {
  const api = `${APIS.AUTH0}?service=users`;
  return await trackPromise(
    fetch(api, {
      method: "POST",
      body: JSON.stringify({
        email: email,
        name: name,
        email_verified: false,
        connection: "StatsPerformUsers",
        verify_email: true,
        password: "GreenLine123!",
        user_metadata: {
          organizationId: +clientHash,
          organizationName: organizationName,
          ...internalUserDetails
        },
        app_metadata: {
          pcms_org_data: {},
        },
      }),
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
  );
};

export const editUserInfo = async ({
  email,
  name,
  userId,
  rolesToAdd,
  rolesToRemove,
  appMetaData,
  userConnection,
  userMetadata,
  accessToken,
}: {
  email: string;
  name: string;
  userId: string;
  rolesToAdd?: string[];
  rolesToRemove?: string[];
  appMetaData?: Record<string, unknown>;
  userConnection?: string;
  userMetadata?: Record<string, unknown>;
  accessToken: string;
}): Promise<Response> => {
  let updateObject: Record<string, unknown> = {};

  if (userConnection && userConnection === "StatsPerformUsers") {
    updateObject = {
      email: email,
      name: name,
    };
  }

  if (userMetadata) {
    if (!userMetadata.organizationId) {
      delete userMetadata.organizationId;
    }

    updateObject.user_metadata = userMetadata;
  }

  if (appMetaData) {
    updateObject.app_metadata = appMetaData;
  }

  const api = `${APIS.AUTH0}?service=update_user`;
  return await trackPromise(
    fetch(api, {
      method: "POST",
      body: JSON.stringify({
        update: updateObject,
        user_id: userId,
        roles_to_add: rolesToAdd || [],
        roles_to_remove: rolesToRemove || [],
      }),
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
  );
};

export const saveClientRole = async (
  clientRole: {
    clientHash: string;
    productId: string;
    isEnabled: boolean;
  },
  accessToken: string
): Promise<Response> => {
  const api = `${APIS.CLIENTS}?service=clientProducts`;
  return await trackPromise(
    fetch(api, {
      method: "POST",
      body: JSON.stringify(clientRole),
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
  );
};

export const saveClientGroup = async (
  clientGroup: {
    clientHash: string;
    groupName: string;
    products: string;
  },
  accessToken: string
): Promise<Response> => {
  const api = `${APIS.CLIENTS}?service=clientGroups`;
  return await trackPromise(
    fetch(api, {
      method: "POST",
      body: JSON.stringify(clientGroup),
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
  );
};

export const removeClientGroup = async (
  groupId: string,
  clientHash: string,
  accessToken: string
): Promise<Response> => {
  const api = `${APIS.CLIENTS}?service=removeClientGroups`;
  return await trackPromise(
    fetch(api, {
      method: "POST",
      body: JSON.stringify({ groupId: groupId, clientHash: clientHash }),
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
  );
};

export const updateUserGroup = async (
  groupId: string,
  users: string[],
  usersToRemove: string[],
  accessToken: string
): Promise<Response> => {
  const api = `${APIS.AUTH0}?service=userGroups`;
  return await trackPromise(
    fetch(api, {
      method: "POST",
      body: JSON.stringify({
        groupId: groupId,
        users: users,
        usersToRemove: usersToRemove,
      }),
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
  );
};

export const saveClientAddOn = async (
    clientAddOn: { addOn: string; isEnabled: boolean; clientHash: string },
    accessToken: string
): Promise<Response> => {
  const api = `${APIS.CLIENTS}?service=clientAddOnsV2`;
  return await trackPromise(
    fetch(api, {
      method: "POST",
      body: JSON.stringify(clientAddOn),
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
  );
};

export const saveClientPackages = async (
  clientPackage: {
    clientHash: string;
    productId: string;
    packageId: string;
  },
  accessToken: string
): Promise<Response> => {
  const api = `${APIS.CLIENTS}?service=clientPackages`;
  return await trackPromise(
    fetch(api, {
      method: "POST",
      body: JSON.stringify(clientPackage),
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
  );
};

export const addUserToRole = async ({
  userId,
  roleId,
  accessToken,
}: {
  userId: string;
  roleId: string;
  accessToken: string;
}): Promise<void> => {
  const addPBLAccessURL = `${APIS.AUTH0}?service=roles`;
  await trackPromise(
    fetch(addPBLAccessURL, {
      method: "PUT",
      body: JSON.stringify({
        users: [userId],
        role_id: roleId,
      }),
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
  );
};

export const addClientProductTournamentCalendar = async (
  leaguesToAdd: ClientProductTournamentCalendar[],
  leaguesToRemove: ClientProductTournamentCalendar[],
  organizationId: string,
  productId: string,
  accessToken: string
): Promise<void> => {
  const addPBLAccessURL = `${APIS.CLIENTS}?service=clientProductsTournamentCalendars`;
  await trackPromise(
    fetch(addPBLAccessURL, {
      method: "POST",
      body: JSON.stringify({
        add: leaguesToAdd,
        remove: leaguesToRemove,
        organizationId: organizationId,
        productId: productId
      }),
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
  );
};

export const getUserList = async (accessToken: string): Promise<User[]> => {
  const response = await trackPromise(
    fetch(`${APIS.AUTH0}?service=users`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
  );
  const responseJson = await response.json();
  return responseJson.users.users;
};

export const getRoleList = async (accessToken: string): Promise<Role[]> => {
  const response = await trackPromise(
    fetch(`${APIS.AUTH0}?service=roles`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
  );
  const responseJson = await response.json();
  return responseJson.roles.roles;
};

export const saveRolePermissions = async (
  rolePermissions: RolePermission[],
  productId: string,
  apiIdentifier: string,
  accessToken: string
): Promise<void> => {
  const api = `${APIS.AUTH0}?service=permissions`;
    const roleNameFilter = productIdToRoleNameFilterMap[productId]
    const body = JSON.stringify({
    role_permissions: rolePermissions,
    name_filter: roleNameFilter,
    api_identifier: decodeURIComponent(apiIdentifier),
  });
  await trackPromise(
    fetch(api, {
      method: "POST",
      body: body,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
  );
};