import {Icon, Table, TBody, TD, TH, THead, TR} from "@stats/playbook-components";
import React from "react";
import styled from "styled-components";
import {League} from "./types";

const StyledTable = styled(Table)`
  .sport-header {
    text-transform: uppercase;
    font-size: 12px;
  }
`;

type SportsLeagueTableProps = {
    sportName: string;
    leagues?: League[];
}

const SportsLeagueTable: React.FC<SportsLeagueTableProps> = ({sportName, leagues}) => {
    return <StyledTable>
        <THead>
            <TR>
                <TH className={"sport-header"}>{sportName}</TH>
                <TH/>
                <TH/>
                <TH/>
            </TR>
        </THead>
        <TBody>
            {leagues && leagues.map((league) => {
                return (
                    <TR key={league.id}>
                        <TD><Icon variant={'chevron-down'} size={'small'}/></TD>
                        <TD>{league.name}</TD>
                        <TD>Reports enabled(#)</TD>
                        <TD>Add-ons enabled(#)</TD>
                    </TR>
                )
            })}
        </TBody>
    </StyledTable>;
}

export default SportsLeagueTable;