import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import ReactDOM from "react-dom";
import {Dropdown, Modal, palette} from "@stats/playbook-components";
import {Switch} from "../Switch";
import {useProductVerticalsProvider} from "../../providers/ProductVerticalsProvider";
import {findProductByPrimaryRoleId} from "../../utils";
import {productIdToRoleNameFilterMap} from "../utils";
import {useApi} from "../../hooks/useApi";
import { AccessTokenOptions, APIS } from "../apiHelpers";
import {Role} from "../AddUser/types";
import {Product} from "../../types";
import {useAuth0} from "@auth0/auth0-react";
import {trackPromise} from "react-promise-tracker";
import {useHistory} from "react-router-dom";
import {
  editGroupPackage,
  getGroupPackage,
  getPackageFeatures,
  GroupPackageResponse,
  SportPackage
} from "./groupApiHelpers";
import { RolesResponse } from "../ProductPage/ProductPage.types";

const StyledModal = styled(Modal)`

  .modalTitleWrapper {
    display: flex;
    flex-direction: column;
  }

  .packageDetails {
    font-weight: bold;
    text-transform: uppercase;
  }

  .lastUpdated {
    font-size: 10px;
    font-style: italic;
  }

  .productEnabledSwitch {
    display: flex;
    margin: 16px 0;
    align-items: center;

    .applicationEnabled {
      margin-left: 6px;
    }
  }

  .featuresAndWidgets {
    font-weight: bold;
  }

  .changePackageFeaturesTitle {
    display: flex;
    margin: 4px 0 13px 0;
    font-size: 12px;
    line-height: 1.33;
    color: ${palette["sp-gray"]};

    .products {
      margin: 0 4px;
      font-weight: bold;
      text-decoration: underline;
      cursor: pointer;
    }
  }
`

const StyledSportDropdown = styled(Dropdown)`
  margin: 20px 0 24px 0;
`

const StyledPackagesDropdown = styled(Dropdown)`
  width: 430px;
`

const StyledPackageFeaturesWrapper = styled.div`
  border: ${palette['sp-light-gray-50']} 1px solid;
  padding: 16px;
  width: calc(100% - 542px);
  height: 248px;
  margin: 16px 0 8px 0;
  overflow-y: scroll;
  line-height: 1.67;
  color: ${palette["sp-gray"]};
`;

type EditGroupPermissionsModalProps = {
  isModalOpen: boolean;
  setIsModalOpen: (x: boolean) => void;
  isProductEnabled: boolean;
  setIsProductEnabled: (x: boolean) => void;
  selectedProduct: Product;
  groupId: string;
  organizationId: string;
}

const EditGroupPermissionsModal: React.FC<EditGroupPermissionsModalProps> = ({
                                                                               isModalOpen,
                                                                               setIsModalOpen,
                                                                               selectedProduct,
                                                                               isProductEnabled,
                                                                               setIsProductEnabled,
                                                                               groupId,
                                                                               organizationId
                                                                             }) => {
  const history = useHistory();
  const {totalProducts} = useProductVerticalsProvider();
  const {id: productName, primaryRoleId} = findProductByPrimaryRoleId(totalProducts, selectedProduct.primaryRoleId);
  
  const nameFilter = productIdToRoleNameFilterMap[productName] || productName
  const {data: packages} = useApi<RolesResponse>(
    `${APIS.AUTH0}?service=roles&name_filter=${nameFilter}&include_permissions=true`
  );
  const packageRoles: Role[] = packages?.roles.roles || [];
  
  const sports = [
    {
      value: 'football',
      name: 'Football'
    },
    {
      value: 'soccer',
      name: 'Soccer'
    },
    {
      value: 'allSports',
      name: 'All Sports'
    }
  ]
  
  const [selectedSport, setSelectedSport] = useState<string>('allSports')
  const [lastUpdatedTime, setLastUpdatedTime] = useState<string>('Never')
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const [selectedSportPackage, setSelectedSportPackage] = useState<string>()
  
  const [modalState, setModalState] = useState<'primary' | 'success' | 'error'>('primary')
  const {getAccessTokenSilently} = useAuth0();
  
  const sportPackages: SportPackage[] = packageRoles.map(packageRole => ({
    name: packageRole.name,
    value: packageRole.id,
    permissions: packageRole.permissions
  } as SportPackage))
  
  useEffect(() => {
    
    const fetchGroupPackages = async (): Promise<void> => {
      const accessToken = await trackPromise(
        getAccessTokenSilently(AccessTokenOptions)
      );
      
      const targetInformation = {
        groupId,
        organizationId,
        productId: selectedProduct.primaryRoleId,
        sportId: selectedSport
      }
      
      await trackPromise(
        getGroupPackage(
          accessToken,
          targetInformation
        ).then((jsonResponse: GroupPackageResponse) => {
            const packageRoleId = jsonResponse.organizationGroupsPackages.map(res => res.packageId)[0]
            const newSelectedSportPackage = sportPackages.find(sportPackage => sportPackage.value === packageRoleId)?.name
            setSelectedSportPackage(newSelectedSportPackage)
            setLastUpdatedTime(jsonResponse.organizationGroupsPackages.map(res => res.lastUpdated)[0] || 'Never')
            return newSelectedSportPackage
          })
          .then(newSelectedSportPackage => {
            getPackageFeatures(newSelectedSportPackage || '', sportPackages)
          })
      );
    };
    
    fetchGroupPackages().then()
    
  }, [organizationId, groupId, primaryRoleId, packageRoles, selectedSport])
  
  const handleSelectSportAndPackage = (selectedItem: string, setFunction: (value: string) => void): void => {
    setModalState('primary')
    setFunction(selectedItem)
    setIsDirty(true)
  }
  
  const adjustProductsFeatures = () => {
    return (
      <span className={'changePackageFeaturesTitle'}>
        {`To adjust features in package, visit the`}
        <div className={'products'} onClick={(): void => {
          history.push(`/products/${selectedProduct.id}`)
        }}>{'Products'}</div>
        {'page.'}
      </span>
    )
  }
  
  return (
    <>
      {isModalOpen &&
      ReactDOM.createPortal(
        <StyledModal
          title={`Edit ${selectedProduct.name} Permissions`}
          style={{height: "672px", width: "940px"}}
          handleClose={(): void => setIsModalOpen(false)}
          variant={modalState}
          modalAction={{
            text: "Save",
            onClick: async (): Promise<void> => {
              const accessToken = await getAccessTokenSilently(AccessTokenOptions)
              const packageId = sportPackages.find(x => x.name === selectedSportPackage)?.value
              if (!packageId) return
              
              const response = await editGroupPackage({
                accessToken,
                organizationId,
                groupId,
                selectedSport,
                packageId,
                productId: selectedProduct.primaryRoleId
              })
              
              setModalState(response.ok ? 'success' : 'error')
            },
            disabled: !isDirty && selectedSportPackage !== undefined
          }}
        >
          <div className={'modalTitleWrapper'}>
            <span className={'packageDetails'}>{'Package Details'}</span>
            <span className={'lastUpdated'}>{`Last Updated at ${lastUpdatedTime}`}</span>
          </div>
          <StyledSportDropdown
            label={'Sport'}
            menuItems={sports}
            value={selectedSport}
            onItemSelect={(newlySelectedSport: string): void => handleSelectSportAndPackage(newlySelectedSport, setSelectedSport)}
          />
          <span className={'featuresAndWidgets'}>
            {'Features & Widgets'}
          </span>
          <div className={'productEnabledSwitch'}>
            <Switch
              onClick={(): void => setIsProductEnabled(!isProductEnabled)}
              checked={isProductEnabled}
              size={'slim'}
            />
            <span className={'applicationEnabled'}>{'Application Enabled'}</span>
          </div>
          
          <div>
            <StyledPackagesDropdown
              menuItems={sportPackages}
              value={selectedSportPackage}
              onItemSelect={(newlySelectedPackage: string): void => {
                const newSportPackage = sportPackages.find((sportPackage) => sportPackage.value === newlySelectedPackage)?.name
                handleSelectSportAndPackage(newSportPackage || '', setSelectedSportPackage);
              }}
            />
            {adjustProductsFeatures()}
            {
              selectedSportPackage &&
              <StyledPackageFeaturesWrapper>
                {getPackageFeatures(selectedSportPackage, sportPackages)}
              </StyledPackageFeaturesWrapper>
            }
          </div>
        </StyledModal>,
        document.body
      )
      }
    </>
  )
}

export default EditGroupPermissionsModal