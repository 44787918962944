import React, { useState } from "react";
import { Dropdown, Modal } from "@stats/playbook-components";
import ReactDOM from "react-dom";
import { User } from "./UsersTable";
import styled from "styled-components";
import { useClientsProvider } from "../../providers/ClientsProvider";
import { editUserInfo, AccessTokenOptions } from "../apiHelpers";
import { trackPromise } from "react-promise-tracker";
import { useAuth0 } from "@auth0/auth0-react";

type VerifyUserModalProps = {
  setOpen: (x: boolean) => void;
  open: boolean;
  actionText: string;
  selectedUser: User;
};

type outlet = {
  outlet_uuid: string;
  outlet_name: string;
  outlet_key: string;
};

const StyledModal = styled(Modal)`
  && {
    height: auto;
    min-height: 324px;
  }
`;

const StyledInfoDiv = styled.div`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.71;
`;

const StyledLabelDiv = styled.div`
  margin-top: 24px;
`;

const StyledDropdown = styled(Dropdown)`
  margin-top: 24px;
`;

type VerifyUserState = "none" | "success" | "error";

const verifyStateTitles = {
  none: "Verify User",
  success: "User Verified",
  error: "User Verification Failed",
};

const VerifyUserModal: React.FC<VerifyUserModalProps> = ({
  open,
  setOpen,
  actionText,
  selectedUser,
}) => {
  const { allClients } = useClientsProvider();
  const [selectOrganization, setSelectOrganization] = useState<string>("");
  const [userVerified, setUserVerified] = useState<VerifyUserState>("none");
  const organizationInfo = allClients?.filter(
    (client) => client.clientId === selectOrganization
  )[0];
  const organizationName = organizationInfo?.organizationName;
  const organizationId = organizationInfo?.clientId;
  const { getAccessTokenSilently } = useAuth0();

  const callEditUserApi = async (): Promise<void> => {
    if (organizationName && organizationId) {
      const existingUserMetadata = selectedUser.user_metadata || {};
      const newUserMetadata = {
        ...existingUserMetadata,
        organizationId: +organizationId,
        organizationName: organizationName,
      };
      const accessToken = await trackPromise(
        getAccessTokenSilently(AccessTokenOptions)
      );
      const response = await editUserInfo({
        email: selectedUser.email,
        name: selectedUser.name,
        userId: selectedUser.user_id,
        userMetadata: newUserMetadata,
        accessToken,
      });
      setUserVerified(response.ok ? "success" : "error");
    }
  };

  const onItemSelect = (newOrganization: string): void => {
    setSelectOrganization(newOrganization);
  };

  return (
    <>
      {open &&
        ReactDOM.createPortal(
          <>
            <StyledModal
              title={verifyStateTitles[userVerified]}
              variant={userVerified === "none" ? "primary" : userVerified}
              handleClose={(): void => setOpen(false)}
              modalAction={{
                text: actionText,
                onClick: callEditUserApi,
              }}
            >
              <StyledLabelDiv>{"Full Name"}</StyledLabelDiv>
              <StyledInfoDiv>{selectedUser.name}</StyledInfoDiv>
              <StyledLabelDiv>{"Email Address"}</StyledLabelDiv>
              <StyledInfoDiv>{selectedUser.email}</StyledInfoDiv>
              <StyledDropdown
                label={"Organization"}
                menuItems={
                  allClients
                    ? allClients.map((client) => {
                        return {
                          value: client.clientId,
                          name: `${client.organizationName} (${client.clientId})`,
                        };
                      })
                    : []
                }
                includeSearch
                value={"Select organization"}
                onItemSelect={onItemSelect}
              />
            </StyledModal>
          </>,
          document.body
        )}
    </>
  );
};

export default VerifyUserModal;
