import React, { useState } from "react";
import styled from "styled-components";
import { palette, Search } from "@stats/playbook-components";
import { useProductVerticalsProvider } from "../../../providers/ProductVerticalsProvider";
import RowWithCheckbox from "./RowWithCheckbox";
import { Product } from "../../../types";

const StyledBoxWrapper = styled.div`
  border: ${palette["sp-light-gray-50"]} 1px solid;
  padding: 16px 8px 0 16px;
  width: 300px;
  margin: 0 0 8px 8px;
`;

const StyledSearch = styled(Search)`
  padding: 0 6px 6px 0;
`;

const StyledTitleAndCount = styled.div`
  width: 324px;
  margin-top: 16px;
  margin-left: 8px;
  font-size: 10px;
  line-height: 1.6;
  display: flex;
  justify-content: space-between;
`;

const StyledScrollWrapper = styled.div`
  height: 324px;
  overflow-y: scroll;
`;

const StyledComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

type ApplicationSelectionForGroupProps = {
  selectedApplications: string[];
  setSelectedApplications: (x: string[]) => void;
};

const ApplicationSelectionForGroup: React.FC<ApplicationSelectionForGroupProps> =
  ({ selectedApplications, setSelectedApplications }) => {
    const [searchTerm, changeSearchTerm] = useState<string>("");
    const { totalProducts } = useProductVerticalsProvider();
    let availableApplications: Product[] = [];
    totalProducts.forEach((vertical) => {
      availableApplications = availableApplications.concat(
        vertical.products.filter((x) =>
          x.name.toLowerCase().startsWith(searchTerm.toLowerCase())
        )
      );
    });
    const toggleApplication = (ApplicationId: string): void => {
      if (selectedApplications?.includes(ApplicationId)) {
        setSelectedApplications(
          selectedApplications.filter((x) => x !== ApplicationId)
        );
      } else {
        setSelectedApplications(selectedApplications.concat([ApplicationId]));
      }
    };

    return (
      <StyledComponentWrapper>
        <StyledTitleAndCount>
          <span>{"Applications"}</span>
          <span>{`${selectedApplications?.length} selected`}</span>
        </StyledTitleAndCount>
        <StyledBoxWrapper>
          <StyledSearch
            searchChangeHandler={changeSearchTerm}
            title={"applicationSearchBar"}
          />
          <StyledScrollWrapper>
            {(availableApplications || []).map((app) => {
              return (
                <RowWithCheckbox
                  onClick={(): void => toggleApplication(app.primaryRoleId)}
                  displayName={app.name}
                  selectedItems={selectedApplications}
                  rowItem={app.primaryRoleId}
                  key={app.primaryRoleId}
                />
              );
            })}
          </StyledScrollWrapper>
        </StyledBoxWrapper>
      </StyledComponentWrapper>
    );
  };

export default ApplicationSelectionForGroup;
