import React, { Dispatch, SetStateAction, useState } from "react";
import { Modal } from "@stats/playbook-components";
import { User } from "../AddUser/types";
import { updateUserGroup, AccessTokenOptions } from "../apiHelpers";
import { useAuth0 } from "@auth0/auth0-react";
import { trackPromise } from "react-promise-tracker";

type DeleteUserFromGroupModalProps = {
  modalOpen: boolean;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  modalState: "none" | "success" | "error";
  setModalState: Dispatch<SetStateAction<"none" | "success" | "error">>;
  setSelectedUser: Dispatch<SetStateAction<User>>;
  selectedUser: User;
  groupId: string;
  forceUpdate: React.DispatchWithoutAction;
};

const DeleteUserFromGroup: React.FC<DeleteUserFromGroupModalProps> = ({
  setModalOpen,
  modalState,
  setModalState,
  selectedUser,
  groupId,
  forceUpdate,
}) => {
  const [modalTitle, setModalTitle] = useState<string>(
    "Permanently Delete User from this Group?"
  );
  const [disableDeleteButton, setDisableDeleteButton] =
    useState<boolean>(false);

  const { getAccessTokenSilently } = useAuth0();

  const handleDeleteUserFromGroup = async (): Promise<void> => {
    const accessToken = await trackPromise(
      getAccessTokenSilently(AccessTokenOptions)
    );
    await updateUserGroup(groupId, [], [selectedUser.user_id], accessToken);
  };

  return (
    <Modal
      title={modalTitle}
      variant={modalState === "none" ? "primary" : modalState}
      handleClose={(): void => {
        setModalOpen(false);
        setModalState("error");
      }}
      modalAction={{
        text: "Delete",
        onClick: (): Promise<void> => {
          return handleDeleteUserFromGroup()
            .then((): void => {
              setModalState("success");
              setDisableDeleteButton(true);
              forceUpdate();
            })
            .catch((e) => {
              console.error("Error occurred", e);
              setModalState("error");
              setModalTitle("Error Deleting User from Group!");
            });
        },
        disabled: disableDeleteButton,
      }}
    >
      {modalState === "success" ? (
        <span> {`${selectedUser.name} has been deleted from Group.`}</span>
      ) : (
        <span>{`Are you sure you want to delete this group from ${selectedUser.name}'s account?`}</span>
      )}
    </Modal>
  );
};

export default DeleteUserFromGroup;
