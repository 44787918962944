import React from "react";
import {ProductVertical} from "../../types";
import styled from "styled-components";
import {palette} from "@stats/playbook-components";

const StyledWrapper = styled.div`
  padding-bottom: 16px;
  
  .product-group-header {
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 2px;
    color: ${palette["sp-light-gray-75"]};
    text-transform: uppercase;
    margin: 16px 0;
  }

  .product-name {
    position: relative;
    font-size: 16px;
    color: ${palette["sp-white"]};
    padding: 8px 16px;
    border-radius: 32px;
    display: flex;
    align-items: center;
    line-height: 1.5;
    left: -16px;
    width: 100%;
    cursor: pointer;

    &.current {
      background-color: ${palette["sp-dark-gray-50"]};
      font-weight: 500;
    }
  }
`

type ProductVerticalGroup = {
  group: ProductVertical;
  selectedProductId: string;
  updateSelectedProduct: (productId: string) => void;
}

const ProductVerticalGroup: React.FC<ProductVerticalGroup> = ({group, selectedProductId, updateSelectedProduct}) => {
  return (
    <StyledWrapper>
      <div
        key={group.verticalName}
        className={'product-group-header'}>
        {group.verticalName}
      </div>
      {group.products.map(p => {
        const currentClassName = p.id === selectedProductId ? 'current' : '';
        return (
          <div
            key={`${p.name}-${p.id}`}
            className={`product-name ${currentClassName}`}
            onClick={(): void => updateSelectedProduct(p.id)}
          >
            {p.name}
          </div>
        )
      })}
    </StyledWrapper>
  );
}

export default ProductVerticalGroup;