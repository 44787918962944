import React from "react";
import styled from "styled-components";
import {Button} from "@stats/playbook-components";
import logo from '../static/new_logo_white.png';
import {useAuth0} from "@auth0/auth0-react";

const StyledDiv = styled.div`
  height: 254px;
  width: 400px;
  margin: 50px auto;
  vertical-align: center;
  background-color: white;
  display: flex;
  border-radius: 4px;
  flex-direction: column;
`

const GradientAndLogo = styled.div`
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  height: 90px;
  background-image: linear-gradient(273deg, rgba(250, 165, 26, 1), rgba(240, 100, 36, 1), rgba(229, 32, 47, 1), rgba(158, 7, 174, 1));
  text-align: center;

  .logo {
    margin: 16px;
  }
`

const MainText = styled.span`
  padding: 12px 0 12px 24px;
  font-size: 21px;
  font-weight: 300;

`

const SecondaryText = styled.span`
  font-size: 12px;
  padding-left: 24px;
`

const StyledButton = styled(Button)`
  margin: auto;
`

const UnauthorizedPage: React.FC = () => {
  const {logout} = useAuth0();
  return (
    <StyledDiv>
      <GradientAndLogo>
        <img className={'logo'} src={logo} alt={'Stats Perform'} height={'58px'}/>
      </GradientAndLogo>
      <MainText>Unauthorized</MainText>
      <SecondaryText>You are not authorized to use this application.</SecondaryText>
      <StyledButton variant={'primary'}
                    onClick={(): void => {
                      logout();
                    }}>
        Log Out
      </StyledButton>
    </StyledDiv>
  )
}

export default UnauthorizedPage;