import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";
import MainPage from "./MainPage";
import { AccessTokenOptions } from "../components/apiHelpers";

export type AppProps = unknown;

type EnvVars = {
  [k: string]: {
    domain: string;
    clientId: string;
    audience: string;
  };
};

const auth0Ids: EnvVars = {
  staging: {
    domain: "authenticate-nonprod.statsperform.com",
    clientId: "D7qKO03X1Ld5045IB2T5zZ1UmYBD6p1O",
    audience: AccessTokenOptions.audience,
  },
  production: {
    domain: "statsperform.us.auth0.com",
    clientId: "MvRM5PxC6hYk4cRQDYeMVsl0U95SG3ts",
    audience: AccessTokenOptions.audience,
  },
};

const App: React.FC<AppProps> = () => {
  return (
    <Auth0Provider
      domain={auth0Ids[process.env.REACT_APP_ENVIRONMENT || "staging"].domain}
      clientId={
        auth0Ids[process.env.REACT_APP_ENVIRONMENT || "staging"].clientId
      }
      redirectUri={window.location.origin}
      audience={
        auth0Ids[process.env.REACT_APP_ENVIRONMENT || "staging"].audience
      }
      scope="access:user-management"
    >
      <MainPage />
    </Auth0Provider>
  );
};

export default App;
