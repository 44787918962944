import React, {useState} from 'react';
import {Table, TBody, TD, TH, THead, Tile, TR, Icon} from '@stats/playbook-components';
import {User} from '../AddUser/types';
import EditGroupUsersModal from './EditGroupUsersModal';
import styled from "styled-components";
import DeleteUserFromGroup from "./DeleteUserFromGroupModal";

type GroupUsersTileProps = {
  groupUsers: User[];
  allUsers: User[];
  groupId: string;
  forceUpdate: React.DispatchWithoutAction;
};

const StyledDeleteIcon = styled(Icon)`
  height: 16px;
  width: 16px;
  margin-right: 0;
`;

const defaultSelectedUser = {
  name: '',
  user_id: '',
  email: '',
  roles: [''],
  user_metadata: [],
  updated_at: '',
  app_metadata: [],
  identities: [{
    connection: ''
  }],
};

const GroupUsersTile: React.FC<GroupUsersTileProps> = ({
                                                         groupUsers,
                                                         allUsers,
                                                         groupId,
                                                         forceUpdate
                                                       }) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalState, setModalState] = useState<'none' | 'success' | 'error'>('error');
  const [selectedUser, setSelectedUser] = useState<User>(defaultSelectedUser);

  return (
    <Tile title={`Users (${groupUsers.length})`} style={{position: 'relative'}}>
      <EditGroupUsersModal
        options={allUsers || []}
        selected={groupUsers}
        groupId={groupId}
        forceUpdate={forceUpdate}
      />
      <Table>
        <THead>
          <TR>
            <TH>{'Users'}</TH>
            <TH>{'Email Address'}</TH>
            <TH style={{width: '4%'}}/>
          </TR>
        </THead>
        <TBody>
          {
            groupUsers.map(x => {
              return (
                <TR key={x.user_id}>
                  <TD>{x.name}</TD>
                  <TD>{x.email}</TD>
                  <TD>
                    <StyledDeleteIcon
                      variant={'delete'}
                      onClick={(): void => {
                        setModalOpen(true);
                        setSelectedUser(x)
                      }}/>
                    {
                      modalOpen &&
                      <DeleteUserFromGroup
                        setModalOpen={setModalOpen}
                        modalOpen={modalOpen}
                        setModalState={setModalState}
                        modalState={modalState}
                        setSelectedUser={setSelectedUser}
                        selectedUser={selectedUser}
                        groupId={groupId}
                        forceUpdate={forceUpdate}
                      />
                    }
                  </TD>
                </TR>
              )
            })
          }
        </TBody>
      </Table>
    </Tile>
  )
};

export default GroupUsersTile;