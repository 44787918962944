import React from "react";
import styled from "styled-components";
import { Icon, palette } from "@stats/playbook-components";
import logo from "../../static/new_logo_white.png";
import PageTab from "./PageTab";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory, useParams } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay-ts";
import { usePromiseTracker } from "react-promise-tracker";
import Loader from "react-loader-spinner";

const OuterBox = styled.div`
  background-color: ${palette["sp-black"]};
  height: 72px;
  display: flex;
  align-items: center;
  padding: 0 8px;
  justify-content: space-between;

  .text {
    color: ${palette["sp-white"]};
    letter-spacing: 5.33px;
    font-size: 16px;
    margin: 31px 0 0 16px;
  }

  .logo {
    margin-left: 32px;
    margin-right: 24px;
  }
`;

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 36px 24px 0 0;
`;

const LogoWrapper = styled.div`
  display: flex;
  flex: 1 1;
`;

const StyledIcon = styled(Icon)`
  flex: 0 0 24px;
  margin-right: 8px;
  padding-bottom: 2px;
  display: inline-block;
  float: left;
`;

const StyledImg = styled.img`
  cursor: pointer;
  height: 50px;
`;

const LogoutWrapper = styled.div`
  cursor: pointer;
  color: ${palette["sp-white"]};
  margin-right: 32px;
  height: 24px;

  span {
    font-size: 18px;
  }
`;

const StatsPerformBanner: React.FC = () => {
  const { logout, isAuthenticated } = useAuth0();
  const history = useHistory();
  const { page } = useParams<{ page: string }>();
  const { promiseInProgress } = usePromiseTracker();

  const setCurrentPage = (newPage: string): void => {
    history.push(`/${newPage}`);
  };

  return (
    <LoadingOverlay
      active={promiseInProgress}
      spinner={<Loader type="Bars" color="#FFFFFF" height="15%" width="15%" />}
    >
      <header>
        <OuterBox title={"outerBox"}>
          <LogoWrapper title={"logoWrapper"}>
            <StyledImg
              className={"logo"}
              src={logo}
              alt={"Stats Perform"}
              onClick={(): void => setCurrentPage("")}
            />
            <span className={"text"}>USER MANAGEMENT</span>
          </LogoWrapper>
          {isAuthenticated && (
            <>
              <LinkWrapper title={"LinkWrapper"}>
                <PageTab
                  onClick={(): void => setCurrentPage("products")}
                  isPageSelected={page === "products"}
                  page={"Products"}
                />
                <PageTab
                  onClick={(): void => setCurrentPage("clients")}
                  isPageSelected={page !== "products"}
                  page={"Clients"}
                />
              </LinkWrapper>
              <LogoutWrapper
                onClick={(): void =>
                  logout({ returnTo: window.location.origin })
                }
              >
                <StyledIcon variant={"account"} fill={"sp-white"} />
                <span>Logout</span>
              </LogoutWrapper>
            </>
          )}
        </OuterBox>
      </header>
    </LoadingOverlay>
  );
};

export default StatsPerformBanner;
