import React, {SetStateAction, useState} from 'react';
import styled from 'styled-components';
import {Button, Tile} from "@stats/playbook-components";
import {ApplicationsProvider} from "../../providers/ApplicationsProvider";
import IntegrationModal, {Application} from "./IntegrationModal";
import {ModalActionState} from "../ClientPage/AddUserModal";
import ApplicationRow from "./ApplicationRow";
import {User} from "../AddUser/types";

const LegacyIntegrationWrapper = styled.div`
  padding-top: 24px;
`;

const StyledIntegrationRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  text-transform: uppercase;

  button {
    font-size: 12px;
  }
`;

type IntegrationTileProps = {
  user: User;
  integrations: Application[];
  setIntegrations: (value: SetStateAction<Application[]>) => void;
  changeDirty: (value: SetStateAction<boolean>) => void;
};

const IntegrationTile: React.FC<IntegrationTileProps> = ({
                                                           user,
                                                           integrations,
                                                           setIntegrations,
                                                           changeDirty
                                                         }) => {
  const [modalState, setModalState] = useState<ModalActionState>('none');
  const [open, setOpen] = useState<boolean>(false);
  
  return (
    <>
      <Tile className={'integration-tile'} title={'Integration'}>
        <StyledIntegrationRow>
          <div>
            {'Integrated Applications'}
          </div>
          <Button variant={'primary-black'}
                  onClick={(): void => setOpen(!open)}>{'Connect Applications'}</Button>
        </StyledIntegrationRow>
        <LegacyIntegrationWrapper>
          {
            integrations.map((x: Application) => {
              return <ApplicationRow
                key={x.id}
                application={x}
                setIntegrations={setIntegrations}
                integrations={integrations}
                changeDirty={changeDirty}
              />
            })
          }
        </LegacyIntegrationWrapper>
      </Tile>
      <ApplicationsProvider>
        <IntegrationModal
          modalTitle={'connect application'}
          modalVariant={modalState === 'none' ? 'primary' : modalState}
          setOpen={setOpen}
          open={open}
          user={user}
          setModalState={setModalState}
          integrations={integrations}
          setIntegrations={setIntegrations}
        />
      </ApplicationsProvider>
    </>
  )
}

export default IntegrationTile;