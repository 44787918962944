import React from "react";
import {
  palette,
  Search,
  Table,
  TH,
  THead,
  Tile,
  TR,
} from "@stats/playbook-components";
import { useParams } from "react-router-dom";
import { APIS } from "../../apiHelpers";
import { useApi } from "../../../hooks/useApi";
import { ClientDetailsResponse, UsersResponse } from "../types";
import styled from "styled-components";
import AddGroupModal from "./AddGroupModal";
import { Group } from "./GroupModal";
import GroupDirectoryRow from "./GroupDirectoryRow";

const StyledTile = styled(Tile)`
  width: 100%;
`;

const StyledTitleRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  .logins-wrapper {
    flex: 1 1;
  }

  .title {
    font-weight: bold;
    text-transform: uppercase;
  }

  .last-updated-text {
    font-size: 10px;
    color: ${palette["sp-dark-gray-25"]};
    font-style: italic;
  }
`;

const StyledSearch = styled(Search)`
  margin-bottom: 24px;
`;

const clientDetailsBaseURL = `${APIS.CLIENTS}?service=client&client_hash=`;
const clientGroupsBaseUrl = `${APIS.CLIENTS}?service=clientGroups&client_hash=`;
export type ClientGroupResponse = {
  clientGroups: Group[];
};
const ClientPageGroups: React.FC = () => {
  const usersBaseURL = `${APIS.AUTH0}?service=users&client_hash=`;
  const { clientHash } = useParams<{ clientHash: string }>();
  const { data: clientDetails } = useApi<ClientDetailsResponse>(
    `${clientDetailsBaseURL}${clientHash}`
  );
  const { data: clientGroups, refresh: forceUpdateGroups } =
    useApi<ClientGroupResponse>(`${clientGroupsBaseUrl}${clientHash}`);
  const { data: allUsers } = useApi<UsersResponse>(
    `${usersBaseURL}${clientHash}`
  );
  const usersWithGroups = allUsers?.users.users.filter(
    (user) => user.user_metadata.groups !== undefined
  );

  return (
    <StyledTile
      title={
        clientDetails &&
        `${clientDetails.client.organizationName} (${clientHash})`
      }
    >
      <StyledTitleRow>
        <div className={"logins-wrapper"}>
          <div className={"title"}>Groups</div>
        </div>
        <AddGroupModal forceUpdateGroups={forceUpdateGroups} />
      </StyledTitleRow>
      <StyledSearch
        searchChangeHandler={(): void => console.log("change search term")}
        title={"clientPageGroupsSearch"}
      />
      <Table>
        <THead>
          <TR>
            <TH />
            <TH>{"Group Id"}</TH>
            <TH>{"Name"}</TH>
            <TH>{"# of Users"}</TH>
            <TH>{"Products"}</TH>
            <TH />
          </TR>
          {clientGroups &&
            clientGroups.clientGroups.map((group) => (
              <GroupDirectoryRow
                group={group}
                usersWithGroups={usersWithGroups}
              />
            ))}
        </THead>
      </Table>
    </StyledTile>
  );
};

export default ClientPageGroups;
