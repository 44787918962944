import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Icon, palette } from "@stats/playbook-components";
import { Link, useHistory } from "react-router-dom";
import ProductVerticalGroup from "./ProductVerticalGroup";
import { useProductVerticalsProvider } from "../../providers/ProductVerticalsProvider";

const StyledWrapper = styled.div`
  .back-link {
    display: flex;
    align-items: center;
    color: ${palette["sp-white"]};
    margin-bottom: 28px;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 12px;
    font-weight: bold;
  }
`;

const ProductPageSide: React.FC = () => {
  const history = useHistory();
  const [selectedProductId, setSelectedProductId] = useState<string>("");
  const { totalProducts } = useProductVerticalsProvider();

  useEffect(() => {
    if (totalProducts.length > 0) {
      const firstProductId = totalProducts[0].products[0].id;
      history.push(`/products/${firstProductId}`);
      setSelectedProductId(firstProductId);
    }
  }, []);

  const updateSelectedProduct = (id: string): void => {
    setSelectedProductId(id);
    history.push(`/products/${id}`);
  };

  return (
    <StyledWrapper>
      <Link className={"back-link"} to={"/clients"}>
        <Icon variant={"chevron-left"} fill={"sp-white"} />{" "}
        <span style={{ margin: "0 0 0 8px" }}>Back to catalogue</span>
      </Link>
      {totalProducts.length > 0
        ? totalProducts.map((productVerticalGroup) => {
            return (
              <ProductVerticalGroup
                key={productVerticalGroup.verticalName}
                group={productVerticalGroup}
                selectedProductId={selectedProductId}
                updateSelectedProduct={updateSelectedProduct}
              />
            );
          })
        : []}
    </StyledWrapper>
  );
};

export default ProductPageSide;
