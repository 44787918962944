import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import {palette} from "@stats/playbook-components";

const StyledWrapper = styled.div`
  width: 40px;
  cursor: pointer;
  &.slim {
    width: 28px;
  }
`;

const StyledSwitch = styled.div`
  background-color: ${palette['sp-light-gray-25']};
  border-radius: 100px;
  padding: 4px;
  transition: background-color 0.5s ease-in-out;

  .checked & {
    background-color: ${palette.serena};
  }

  .slim & {
    padding: 2px 4px;
  }
`;

const StyledHandle = styled.div`
  border-radius: 100px;
  height: 16px;
  width: 16px;
  background-color: ${palette['sp-mid-gray']};
  transition: all 0.5s ease-in-out;
  transition-property: background-color, transform;
  .checked & {
    background-color: ${palette['sp-white']};
    opacity: 0.8;
    transform: translate(16px, 0);
  }
  .slim & {
    height: 11px;
    width: 11px;
  }
  .slim.checked & {
    transform: translate(9px, 0);
  }
`;

type SwitchSizeType = 'normal' | 'slim';
type CheckboxAttributes = React.HTMLAttributes<HTMLInputElement>;
type SwitchType = {
    size?: SwitchSizeType;
    checked?: boolean;
    changeChecked?: (checked: boolean) => void;
};
export type SwitchProps = CheckboxAttributes & SwitchType;

export const Switch: React.FC<SwitchProps> = (props) => {
    const { className, onClick, checked, changeChecked, size = 'normal', ...rest } = props;

    const toggleChecked: React.MouseEventHandler<HTMLInputElement> = (
        event
    ): void => {
        changeChecked && changeChecked(!checked);
        onClick && onClick(event);
    };

    const switchClassName = classNames(className, size, {
        checked: checked,
    });
    return (
        <StyledWrapper
            className={switchClassName}
            onClick={toggleChecked}
            {...rest}
        >
            <input title={'switch'} type={'checkbox'} style={{ display: 'none' }} />
            <StyledSwitch>
                <StyledHandle />
            </StyledSwitch>
        </StyledWrapper>
    );
};
