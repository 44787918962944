import React from 'react';
import {Dropdown, Icon, palette} from "@stats/playbook-components";
import styled from "styled-components";

const StyledOuter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledWrapper = styled.div`
  font-size: 12px;
  width: 192px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  background: ${palette['sp-light-gray-25']};
  border-radius: 16px;
`;

const StyledDropdown = styled(Dropdown)`
  margin: 6px 0 6px 0;
  padding: 0 2px 0 2px;
  background: white;
  
  .dropdown-field .secondary {
    width: 8px;
    padding: 0 4px 0 0;
  }
  
  .secondary {
    width: 90px;
  }
`;

const DropdownWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TextWrapper = styled.div`
  padding: 6px;
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
  
  &.noClick {
    cursor: default;
    pointer-events: none;
  }
`;

type PaginationProps = {
  currentPage: number;
  setPage: (page: number) => void;
  totalPages: number;
}

const Pagination: React.FC<PaginationProps> = ({
                                                 setPage,
                                                 currentPage,
                                                 totalPages
                                               }) => {
  const firstPage = currentPage === 0;
  const lastPage = currentPage === totalPages - 1;

  const menuItems = [...Array(totalPages > 1 ? totalPages : 1).keys()].map(x => {
    return {
      value: String(x + 1)
    }
  });

  const dropdownMenuChange = (value: string): void => {
    setPage(Number(value) - 1)
  };

  if (!totalPages) return null

  return (
    <StyledOuter>
      <StyledWrapper>
        <StyledIcon
          className={firstPage ? 'noClick' : ''}
          variant={'order-first'}
          size={'small'}
          onClick={(): void => setPage(0)}
          fill={firstPage ? 'sp-light-gray-75' : 'sp-black'}
        />
        <StyledIcon
          className={firstPage ? 'noClick' : ''}
          variant={'chevron-left'}
          size={'small'}
          onClick={(): void => setPage(currentPage - 1)}
          fill={firstPage ? 'sp-light-gray-75' : 'sp-black'}
        />
        <DropdownWrapper>
          <StyledDropdown
            key={currentPage + 1}
            menuItems={menuItems}
            onItemSelect={dropdownMenuChange}
            value={String(currentPage + 1)}
            variant={'secondary'}
          />
          <TextWrapper>
            {` of ${totalPages}`}
          </TextWrapper>
        </DropdownWrapper>
        <StyledIcon
          className={lastPage ? 'noClick' : ''}
          variant={'chevron-right'}
          size={'small'}
          onClick={(): void => setPage(currentPage + 1)}
          fill={lastPage ? 'sp-light-gray-75' : 'sp-black'}
        />
        <StyledIcon
          className={lastPage ? 'noClick' : ''}
          variant={'order-last'}
          size={'small'}
          onClick={(): void => setPage(totalPages - 1)}
          fill={lastPage ? 'sp-light-gray-75' : 'sp-black'}
        />
      </StyledWrapper>
    </StyledOuter>
  )
};

export default Pagination;