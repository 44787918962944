import React from 'react';
import styled from 'styled-components';
import {Icon} from "@stats/playbook-components";

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
`;

type RoleRowProps = {
  onClick: () => void;
  displayName: string;
  roles: string[];
  role: string;
}

const RoleRow: React.FC<RoleRowProps> = ({
  onClick,
  displayName,
  roles,
  role
                                         }) => {
  return (
    <StyledWrapper onClick={onClick} key={role}>
      <Icon
        variant={roles?.includes(role) ? 'checkbox-selected': 'checkbox-unselected'}
        size={'small'}
      />
      <span style={{marginLeft: '4px'}}>{displayName}</span>
    </StyledWrapper>
  )
}

export default RoleRow;