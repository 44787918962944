import React from 'react';
import {Button} from "@stats/playbook-components";
import GroupModal from "./GroupModal";

type AddGroupModalProps = {
  forceUpdateGroups: () => void;
}

const AddGroupModal: React.FC<AddGroupModalProps> = ({forceUpdateGroups}) => {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <GroupModal
        setOpen={setOpen}
        open={open}
        modalTitle={'Create Group'}
        actionText={'Create'}
        modalCallback={(): void => console.log('modal callback')}
        forceUpdateGroups={forceUpdateGroups}
      />
      <Button variant={'primary'} onClick={(): void => setOpen(!open)}>{'Add Group'}</Button>
    </>
  )
};

export default AddGroupModal;