import React from "react";
import { APIS } from "../components/apiHelpers";
import { Product } from "../../src/types";
import { useApi } from "../hooks/useApi";

export type ProductVerticalResponse = {
  verticalName: string;
  products: Product[];
};

type ProductVerticalsProviderType = {
  totalProducts: ProductVerticalResponse[];
};

const DefaultProductVerticalsContext = {
  totalProducts: [],
};

export const ProductVerticalsContext =
  React.createContext<ProductVerticalsProviderType>(
    DefaultProductVerticalsContext
  );

const productVerticalsURL = `${APIS.CLIENTS}?service=verticals`;

export const ProductVerticalsProvider: React.FC = (props) => {
  const { data: totalProducts } = useApi<{
    verticals: ProductVerticalResponse[];
  }>(productVerticalsURL);

  const providerValue = {
    totalProducts: totalProducts ? totalProducts.verticals : [],
  };
  return (
    <ProductVerticalsContext.Provider value={providerValue}>
      {props.children}
    </ProductVerticalsContext.Provider>
  );
};

export const useProductVerticalsProvider = (): ProductVerticalsProviderType => {
  return React.useContext(ProductVerticalsContext);
};
