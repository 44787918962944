import React from 'react'
import styled, {css} from 'styled-components'
import {Input, palette} from "@stats/playbook-components";
import {CountryDropdown, RegionDropdown} from "react-country-region-selector";
import {InternalUserDetailUpdateFunctions, InternalUserDetailValues} from "../../types/UserProfileTypes";


const countryAndRegionDropdownStyles = css`
  margin-top: 2px;
  margin-bottom: 16px;
  z-index: 1;
  min-width: 30px;
  padding: 8px 8px 8px 16px;
  border-radius: 2px;
  border: solid 1px ${palette['sp-light-gray-50']};
  background-color: ${palette['sp-light-gray-25']};
  font-size: 14px;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  color: ${palette['sp-black']} !important;
  line-height: 1.71;
  flex: 1 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  -webkit-appearance: none;
  appearance: none;
`

const StyledCountryDropdown = styled(CountryDropdown)<{inputFieldWidth: string}>`
  width: ${(props): string => props.inputFieldWidth};
  ${countryAndRegionDropdownStyles}
`

const StyledRegionDropdown = styled(RegionDropdown)<{inputFieldWidth: string}>`
  width: ${(props): string => props.inputFieldWidth};
  ${countryAndRegionDropdownStyles}
`
const CountryRegionLabelWrapper = styled.div<{ labelWidth: string }>`
  display: flex;
  width: ${(props): string => props.labelWidth};
  justify-content: flex-start;
`

const StyledCountryOrRegionLabel = styled.span`
  flex: 1 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 10px;
  font-weight: normal;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: ${palette['sp-black']};
  display: flex;
  align-items: center;
  
  .required {
    color: ${palette['jordan']};
    margin-left: 2px;
  }
`

const PhoneNumberWrapper = styled.div<{inputFieldWidth: string}>`
  display: flex;
  flex-direction: row;
  width: ${(props): string => props.inputFieldWidth};
  justify-content: space-between;
  margin: 16px 0;
`

type InternalUserDetailsProps = {
  internalUserDetails: InternalUserDetailValues;
  internalUserDetailUpdateFunctions: InternalUserDetailUpdateFunctions;
  setDirty: (x: boolean) => void;
  inputFieldWidth?: number;
}

const InternalUserDetails: React.FC<InternalUserDetailsProps> = ({
                                                                   internalUserDetails,
                                                                   internalUserDetailUpdateFunctions,
                                                                   setDirty,
                                                                   inputFieldWidth = 264
                                                                 }) => {

  const {city, country, region, officeLocation, countryCode, telephone, secondaryEmail} = internalUserDetails;
  const {
    setCity,
    setCountry,
    setRegion,
    setOfficeLocation,
    setCountryCode,
    setTelephone,
    setSecondaryEmail
  } = internalUserDetailUpdateFunctions

  const handleChangeField = (
    event: React.FormEvent<HTMLInputElement>,
    setFieldFunction: (x: string) => void
  ): void => {
    setFieldFunction(event.currentTarget.value)
    setDirty(true)
  }

  const handleChangeCountryOrRegion = (
    value: string,
    setFieldFunction: (x: string) => void
  ): void => {
    setFieldFunction(value)
    setDirty(true)
  }

  return (
    <div>
      <div style={{width: `${inputFieldWidth}px`, margin: '16px 0px'}}>
        <Input
          label={'Secondary Email Address (Optional)'}
          placeholder={'Enter secondary email address'}
          value={secondaryEmail}
          onChange={(event): void => handleChangeField(event, setSecondaryEmail)}
          data-testid={'secondary-email-input'}
        />
      </div>
      <CountryRegionLabelWrapper labelWidth={'44px'}>
        <StyledCountryOrRegionLabel>
          {'Country'}
          <span className={'required'}>{'*'}</span>
        </StyledCountryOrRegionLabel>
      </CountryRegionLabelWrapper>
      <StyledCountryDropdown
        value={country || ''}
        onChange={(country): void => handleChangeCountryOrRegion(country, setCountry)}
        inputFieldWidth={`${inputFieldWidth}px`}
        data-testid={'country-dropdown'}
      />
      <CountryRegionLabelWrapper labelWidth={'38px'}>
        <StyledCountryOrRegionLabel>
          {'Region'}
          <span className={'required'}>{'*'}</span>
        </StyledCountryOrRegionLabel>
      </CountryRegionLabelWrapper>
      <StyledRegionDropdown
        country={country || ''}
        value={region || ''}
        onChange={(region): void => handleChangeCountryOrRegion(region, setRegion)}
        inputFieldWidth={`${inputFieldWidth}px`}
        data-testid={'region-dropdown'}
      />
      <div style={{width: `${inputFieldWidth}px`}}>
        <Input
          label={'City'}
          placeholder={'Enter city'}
          value={city}
          required={true}
          onChange={(event): void => handleChangeField(event, setCity)}
          data-testid={'city-textinput'}
        />
      </div>
      <div style={{width: `${inputFieldWidth}px`, margin: '16px 0px'}}>
        <Input
          label={'Office Location'}
          placeholder={'Office location'}
          value={officeLocation}
          required={true}
          onChange={(event): void => handleChangeField(event, setOfficeLocation)}
          data-testid={'office-location-input'}
        />
      </div>
      <PhoneNumberWrapper inputFieldWidth={`${inputFieldWidth}px`}>
        <div style={{width: '80px'}}>
          <Input
            label={'Country Code'}
            value={countryCode}
            required={true}
            onChange={(event): void => handleChangeField(event, setCountryCode)}
            data-testid={'country-code-input'}
          />
        </div>
        <div style={{width: `${inputFieldWidth - 96}px`}}>
          <Input
            label={'Telephone'}
            placeholder={'(_ _ _) _ _ _ - _ _ _ _'}
            value={telephone}
            required={true}
            onChange={(event): void => handleChangeField(event, setTelephone)}
            data-testid={'telephone-input'}
          />
        </div>
      </PhoneNumberWrapper>
    </div>
  )
}

export default InternalUserDetails;