import React from "react";
import { useApi } from "../hooks/useApi";
import { APIS } from "../components/apiHelpers";

export type ClientResponse = {
  clientId: string;
  organizationName: string;
  lastUpdated: string;
  userCount: number;
  products: string[];
};

type ClientsProviderType = {
  allClients?: ClientResponse[];
};

const DefaultClientsContext = {
  allClients: [],
};

export const ClientsContext = React.createContext<ClientsProviderType>(
  DefaultClientsContext
);

const clientsURL = `${APIS.CLIENTS}?service=clients&page_size=2000`;

export const ClientsProvider: React.FC = (props) => {
  const { data: allClients } =
    useApi<{ clients: ClientResponse[] }>(clientsURL);

  const providerValue = {
    allClients: allClients && allClients.clients,
  };
  return (
    <ClientsContext.Provider value={providerValue}>
      {props.children}
    </ClientsContext.Provider>
  );
};

export const useClientsProvider = (): ClientsProviderType => {
  return React.useContext(ClientsContext);
};
