import React, {useState} from "react";
import {useParams} from "react-router-dom";
import styled from "styled-components";
import {palette, Tabs, Tile} from "@stats/playbook-components";
import PackageDetails from "../ClientPage/PackageDetails/PackageDetails";
import LeaguesAndCompetitions from "../ClientPage/LeaguesAndCompetitions";
import {ClientProductTournamentCalendarProvider} from "../../providers/ClientProductTournamentCalendarProvider";
import {useProductVerticalsProvider} from "../../providers/ProductVerticalsProvider";
import {findProductById} from "../../utils"

const StyledTitleRow = styled.div`
  display: flex;
  padding: 0 24px 16px 24px;
  font-size: 21px;
  font-weight: 300;
  border-bottom: 1px solid ${palette['sp-light-gray-25']};
  margin: 0 -24px 24px;
  .title {
    flex: 1 1;
  }
`;

function getTabList(productId: string): {title: string; id: string}[] {
    const pressBoxLiveIds = [
        'rol_N2T4W7PfV28AJkPB', //stage
        'rol_qaHpt7LnMNWfk7WM' //prod
    ]
    const isPressBoxLive = pressBoxLiveIds.includes(productId)

    const tabList = [{title: 'Package Details', 'id': 'packageDetails'}];
    if (isPressBoxLive) {
        tabList.push({title: 'Leagues & Competitions', 'id': 'leaguesAndCompetitions'})
    }

    return tabList
}

const ClientProductPage: React.FC = () => {
  const {productId, clientHash} = useParams<{ productId: string; clientHash: string }>();
  const { totalProducts } = useProductVerticalsProvider();
  const productName = findProductById(totalProducts, productId).name;
  const [activeTab, setActiveTab] = useState('Package Details');

    return (
    <ClientProductTournamentCalendarProvider clientHash={clientHash} productId={productId}>
      <Tile style={{'width': '100%'}}>
        <StyledTitleRow>
          <div className={'title'}>{productName}</div>
          <Tabs
            tabList={getTabList(productId)}
            onChangeCallback={(id: string): void => {
              setActiveTab(id);
            }}
          />
        </StyledTitleRow>
        {
          activeTab === 'leaguesAndCompetitions' ?
            <LeaguesAndCompetitions clientHash={clientHash} productId={productId}/> :
            <PackageDetails/>
        }
      </Tile>
    </ClientProductTournamentCalendarProvider>
  )
};

export default ClientProductPage;