import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';

// if (process.env.NODE_ENV === 'development') {
//     //eslint-disable-next-line @typescript-eslint/no-var-requires
//     const { worker } = require('./mocks/browser');
//     worker.start().then();
// }

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);