import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import StatsPerformBanner from "./StatsPerformBanner/StatsPerformBanner";
import UserManagementDashboard from "./UserManagementDashboard";
import ClientPage from "./ClientPage/ClientPage";
import { ClientsProvider } from "../providers/ClientsProvider";
import { ProductVerticalsProvider } from "../providers/ProductVerticalsProvider";
import UserProfile from "./UserProfile/UserProfile";
import ProductPage from "./ProductPage/ProductPage";
import { useAuth0 } from "@auth0/auth0-react";
import { Loading } from "./Loading/Loading";
import UnauthorizedPage from "./UnauthorizedPage";

const MainPage: React.FC = () => {
  const { error, isAuthenticated, loginWithRedirect, isLoading } = useAuth0();
  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <UnauthorizedPage />;
  }

  if (!isAuthenticated) {
    loginWithRedirect();
  }

  return (
    <ProductVerticalsProvider>
      <ClientsProvider>
        <Router>
          <Switch>
            <Route path={"/:page"}>
              <StatsPerformBanner />
            </Route>
            <Route path={"/"}>
              <StatsPerformBanner />
            </Route>
          </Switch>
          <Switch>
            <Route path={"/clients/:clientHash/product/:productId"}>
              <ClientPage />
            </Route>
            <Route path={"/clients/:clientHash/groups/:groupId"}>
              <ClientPage />
            </Route>
            <Route path={"/clients/:clientHash/groups"}>
              <ClientPage />
            </Route>
            <Route path={"/clients/:clientHash"}>
              <ClientPage />
            </Route>
            <Route path={"/clients"}>
              <UserManagementDashboard />
            </Route>
            <Route path={"/user/:userId"}>
              <UserProfile />
            </Route>
            <Route path={"/products/:productId"}>
              <ProductPage />
            </Route>
            <Route path={"/products"}>
              <ProductPage />
            </Route>
            <Route>
              <UserManagementDashboard />
            </Route>
          </Switch>
        </Router>
      </ClientsProvider>
    </ProductVerticalsProvider>
  );
};

export default MainPage;
