import React, { useState } from "react";
import {
  Icon,
  palette,
  Search,
  SortableTH,
  Table,
  TBody,
  TD,
  TH,
  THead,
  Tile,
  TR,
} from "@stats/playbook-components";
import { useHistory, useParams } from "react-router-dom";
import { APIS, AccessTokenOptions } from "../apiHelpers";
import { User } from "../AddUser/types";
import { useApi } from "../../hooks/useApi";
import { ClientDetailsResponse, UsersResponse } from "./types";
import styled from "styled-components";
import Pagination from "../ClientCatalogue/Pagination/Pagination";
import AddUserModal from "./AddUserModal";
import _ from "lodash";
import { trackPromise } from "react-promise-tracker";
import { useDebounce } from "../ClientCatalogue/useDebounce";
import { useAuth0 } from "@auth0/auth0-react";

const clientDetailsBaseURL = `${APIS.CLIENTS}?service=client&client_hash=`;
const usersBaseURL = `${APIS.AUTH0}?service=users`;

const StyledTile = styled(Tile)`
  width: 100%;
`;

const ClickableRow = styled(TR)`
  cursor: pointer;
`;

const StyledTitleRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  .logins-wrapper {
    flex: 1 1;
  }

  .title {
    font-weight: bold;
    text-transform: uppercase;
  }

  .last-updated-text {
    font-size: 10px;
    color: ${palette["sp-dark-gray-25"]};
    font-style: italic;
  }
`;

const StyledSearch = styled(Search)`
  margin-bottom: 24px;
`;

type ClientPageProps = {
  pageSize?: number;
};

const ClientPage: React.FC<ClientPageProps> = ({ pageSize = 20 }) => {
  const history = useHistory();
  const { clientHash } = useParams<{ clientHash: string }>();
  const [sortDir, changeSortDir] = useState<boolean>(true);
  const [sortCol, changeSortCol] = useState<string>("name");
  const [urlSortParameter, setURLSortParameter] = useState<string>("name:1");
  const [searchTerm, changeSearchTerm] = useState<string>();
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(0);
  const [allUsers, setAllUsers] = useState<User[]>([]);
  const [totalUsers, setTotalUsers] = useState<number>(0);
  const { data: clientDetails } = useApi<ClientDetailsResponse>(
    `${clientDetailsBaseURL}${clientHash}`
  );
  const [totalPages, setTotalPages] = useState<number>(0);
  const [usersToDisplay, setUsersToDisplay] = useState(allUsers);
  const lastUpdated = _.max(allUsers?.map((user) => user.updated_at));
  const lastUpdatedText = lastUpdated
    ? new Date(lastUpdated).toDateString()
    : "";

  const debouncedSearchTerm = useDebounce(searchTerm, 750);
  const { getAccessTokenSilently } = useAuth0();

  React.useEffect(() => {
    setCurrentPageNumber(0);
  }, [searchTerm]);

  React.useEffect(() => {
    const sortDirection = sortDir ? "1" : "-1";
    setURLSortParameter(`${sortCol}:${sortDirection}`);
  }, [sortDir, sortCol]);

  React.useEffect(() => {
    const searchTermQueryParam = searchTerm ? `&search=${searchTerm}` : "";
    const usersUrl = `${usersBaseURL}&client_hash=${clientHash}&page=${currentPageNumber}${searchTermQueryParam}&sort=${urlSortParameter}`;
    let isMounted = true;
    const fetchUsers = async (): Promise<void> => {
      const accessToken = await trackPromise(
        getAccessTokenSilently(AccessTokenOptions)
      );
      trackPromise(
        fetch(usersUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then((response) => response.json())
          .then((jsonResponse: UsersResponse) => {
            const total: number = jsonResponse.users.total;
            const allUsers: User[] = jsonResponse.users.users;
            if (isMounted) {
              setTotalUsers(total);
              setAllUsers(allUsers);
            }
          })
      );
    };
    fetchUsers().then();

    return (): void => {
      isMounted = false;
    };
  }, [currentPageNumber, debouncedSearchTerm, urlSortParameter]);

  React.useEffect(() => {
    if (allUsers) {
      setUsersToDisplay(allUsers);
      setTotalPages(Math.ceil(totalUsers / pageSize));
    }
  }, [allUsers, totalUsers, pageSize]);

  const sortableHeader = (
    columnId: string,
    columnTitle: string
  ): React.ReactNode => {
    const isSorted = sortCol.includes(columnId);
    return (
      <SortableTH
        changeSortCol={changeSortCol}
        changeSortDir={changeSortDir}
        columnId={columnId}
        columnTitle={columnTitle}
        isSorted={isSorted}
        sortDescending={sortDir}
      />
    );
  };
  return (
    <StyledTile
      title={
        clientDetails &&
        `${clientDetails.client.organizationName} (${clientHash})`
      }
    >
      <StyledTitleRow>
        <div className={"logins-wrapper"}>
          <div className={"title"}>User Logins</div>
          <div className={"last-updated-text"}>
            Last updated at: {lastUpdatedText}
          </div>
        </div>
        <AddUserModal
          organizationName={
            (clientDetails && clientDetails.client.organizationName) || ""
          }
        />
      </StyledTitleRow>
      <StyledSearch
        searchChangeHandler={changeSearchTerm}
        title={"clientPageUserSearch"}
      />
      <Table>
        <THead>
          <ClickableRow>
            {sortableHeader("user_id", "User Id")}
            {sortableHeader("name", "Name")}
            {sortableHeader("email", "Email")}
            <TH />
          </ClickableRow>
        </THead>
        <TBody>
          {usersToDisplay &&
            usersToDisplay.map((user) => {
              return (
                <TR key={user.user_id}>
                  <TD style={{ width: "33%" }}>{user.user_id}</TD>
                  <TD style={{ width: "33%" }}>{user.name}</TD>
                  <TD style={{ width: "33%" }}>{user.email}</TD>
                  <TD style={{ cursor: "pointer" }}>
                    <Icon
                      variant={"edit"}
                      onClick={(): void => {
                        history.push(`/user/${user.user_id}`);
                      }}
                    />
                  </TD>
                </TR>
              );
            })}
        </TBody>
      </Table>
      <Pagination
        currentPage={currentPageNumber}
        setPage={setCurrentPageNumber}
        totalPages={totalPages}
      />
    </StyledTile>
  );
};

export default ClientPage;
