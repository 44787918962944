import React from "react";
import {trackPromise} from "react-promise-tracker";
import { APIS } from "../apiHelpers";
import { Permission } from "../ProductPage/ProductPage.types";

type GroupPackage = {
  organizationIdGroupId: string;
  productIdSportId: string;
  packageId: string;
  lastUpdated: string;
}

export type SportPackage = {
  name: string;
  value: string;
  permissions?: Permission[];
}

export type GroupPackageResponse = {
  organizationGroupsPackages: GroupPackage[];
}

export const getGroupPackage = async (
  accessToken: string,
  targetInformation: Record<string, string>
): Promise<GroupPackageResponse> => {
  
  const {organizationId, groupId, productId, sportId} = targetInformation
  const url = `${APIS.CLIENTS}?service=organizationGroupsPackages&organizationId=${organizationId}&groupId=${groupId}&productId=${productId}&sportId=${sportId}`
  
  return await fetch(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }).then((response) => response.json())
  
}

export const editGroupPackage = async ({
                                         accessToken,
                                         organizationId,
                                         groupId,
                                         productId,
                                         packageId,
                                         selectedSport
                                       }:
                                         {
                                           accessToken: string;
                                           organizationId: string;
                                           groupId: string;
                                           productId: string;
                                           packageId: string;
                                           selectedSport: string;
                                         }): Promise<Response> => {
  const api = `${APIS.CLIENTS}?service=organizationGroupsPackages`
  
  return await trackPromise(
    fetch(api, {
      method: "POST",
      body: JSON.stringify({
        organizationId,
        groupId,
        productId,
        packageId,
        sportId: selectedSport || 'allSports'
      }),
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
  );
}

export const getPackageFeatures = (selectedPackage: string, sportPackages: SportPackage[]) => {
  const selectedPackagePermissions = sportPackages.find(sportPackage => sportPackage?.name === selectedPackage)?.permissions
  return selectedPackagePermissions?.map(packagePermission => <div>{packagePermission.description}</div>)
}