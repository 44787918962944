import React, {useState} from 'react';
import styled from 'styled-components';
import {Search, palette} from "@stats/playbook-components";
import RoleRow from "./RoleRow";
import {Product} from "../../types";
import {useProductVerticalsProvider} from "../../providers/ProductVerticalsProvider";

const StyledWrapper = styled.div`
  border: ${palette['sp-light-gray-50']} 1px solid;
  padding: 16px;
  width: calc(100% - 50px);
  margin-bottom: 8px;
`;

const StyledSearch = styled(Search)`
  padding-bottom: 6px;
`;

const StyledScrollWrapper = styled.div`
  height: 144px;
  overflow-y: scroll;
`;

type ApplicationSelectionProps = {
  setRoles: (x: string[]) => void;
  roles: string[];
}

const ApplicationSelection: React.FC<ApplicationSelectionProps> = ({
                                                                     setRoles,
                                                                     roles
                                                                   }) => {

  const [applicationSearch, setApplicationSearch] = useState<string>('');
  const { totalProducts } = useProductVerticalsProvider();
  let viableRoles: Product[] = [];
  totalProducts.forEach(vertical => {
    viableRoles = viableRoles.concat(vertical.products.filter(x => x.name.toLowerCase().startsWith(applicationSearch.toLowerCase())));
  });

  const toggleRole = (role: string): void => {
    if (roles?.includes(role)) {
      setRoles(roles.filter((x) => x !== role))
    } else {
      setRoles(roles.concat([role]))
    }
  };

  return (
    <StyledWrapper>
      <StyledSearch searchChangeHandler={(x: string): void => setApplicationSearch(x)} title={'searchBar'}/>
      <StyledScrollWrapper>
        {
          viableRoles.map((role: Product) => {
            return <RoleRow
              onClick={(): void => toggleRole(role.primaryRoleId)}
              displayName={role.name}
              roles={roles}
              role={role.primaryRoleId}
              key={role.id}
            />;
          })
        }
      </StyledScrollWrapper>
    </StyledWrapper>
  )
};

export default ApplicationSelection;