import React, { Dispatch, SetStateAction, useState } from "react";
import ReactDOM from "react-dom";
import { Modal } from "@stats/playbook-components";
import { APIS, AccessTokenOptions } from "../apiHelpers";
import { trackPromise } from "react-promise-tracker";
import { useAuth0 } from "@auth0/auth0-react";

type DeleteUserModalProps = {
  modalOpen: boolean;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  modalState: "none" | "success" | "error";
  setModalState: Dispatch<SetStateAction<"none" | "success" | "error">>;
  userId: string;
  redirectToUsers: () => void;
};

const DeleteUserModal: React.FC<DeleteUserModalProps> = ({
  modalOpen,
  modalState,
  setModalOpen,
  setModalState,
  userId,
  redirectToUsers,
}) => {
  const [modalTitle, setModalTitle] = useState<string>(
    "Permanently Delete User?"
  );
  const { getAccessTokenSilently } = useAuth0();

  const handleDeleteUser = async (): Promise<boolean> => {
    const accessToken = await trackPromise(
      getAccessTokenSilently(AccessTokenOptions)
    );
    return trackPromise(
      fetch(`${APIS.AUTH0}?service=users`, {
        method: "DELETE",
        body: JSON.stringify({
          user_id: userId,
        }),
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }).then((response) => response.status === 200)
    );
  };

  return (
    <>
      {modalOpen &&
        ReactDOM.createPortal(
          <Modal
            title={modalTitle}
            handleClose={(): void => {
              setModalState("error");
              setModalOpen(false);
            }}
            variant={modalState === "none" ? "primary" : modalState}
            modalAction={{
              text: "Delete User",
              onClick: (): void => {
                handleDeleteUser().then((successfulResponse) => {
                  if (successfulResponse) {
                    redirectToUsers();
                  } else {
                    setModalState("error");
                    setModalTitle("Error Deleting User!");
                  }
                });
              },
            }}
          >
            <span>
              {"Are you sure you want to delete this user's account?\n"}
            </span>
            <span>{"Once deleted, this action cannot be undone."}</span>
          </Modal>,
          document.body
        )}
    </>
  );
};

export default DeleteUserModal;
