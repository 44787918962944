import React from 'react';
import styled from 'styled-components';
import {Link} from "react-router-dom";
import {palette} from "@stats/playbook-components";

const StyledLink = styled(Link)`
  display: inline-block;
  color: ${palette["sp-white"]};
  line-height: 1.33;
  font-size: 18px;
  width: 73px;
  height: 24px;
  text-decoration: none;
  margin-bottom: 8px;
`;

const StyledGradient = styled.div`
  height: 4px;
  width: 74px;

  &.black {
    background-color: unset;
  }

  &.gradient {
    background-image: linear-gradient(273deg,
    rgba(250, 165, 26, 1),
    rgba(240, 100, 36, 1),
    rgba(229, 32, 47, 1),
    rgba(158, 7, 174, 1));
  }
`;

const StyledTabGradientWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 0 16px;
`;

type PageTabProps = {
  onClick: () => void;
  isPageSelected: boolean;
  page: string;
}

const PageTab: React.FC<PageTabProps> = ({onClick, isPageSelected, page}) => {
  return (
    <StyledTabGradientWrapper title={'StyledTabGradientWrapper'}>
      <StyledLink
        to={`/${page.toLowerCase()}`}
        onClick={onClick}
        className={isPageSelected ? "selected" : ""}
      >
        {page}
      </StyledLink>
      <StyledGradient className={isPageSelected ? 'gradient' : 'black'} title={'styledGradient'}/>
    </StyledTabGradientWrapper>
  );
};

export default PageTab;