import React from "react";
import {palette} from "@stats/playbook-components";
import styled from "styled-components";
import ClientPageSide from "./ClientPageSide";

const StyledWrapper = styled.div`
  display: flex;
  background-color: ${palette["sp-light-gray-25"]};
  min-height: calc(100vh - 72px);
  height: calc(100% - 72px);

  .side {
    flex: 0 0 272px;
    background-color: ${palette["sp-black"]};
    padding: 24px;
  }

  .main {
    margin: 16px;
    width: calc(100vw - 272px);
  }
`

type BaseClientPageProps = {
  clientHash: string;
  MainPageComponent: React.ReactNode;
}

const BaseClientPage: React.FC<BaseClientPageProps> = (props) => {
  return (
    <StyledWrapper>
      <div className={'side'}>
        <ClientPageSide clientHash={props.clientHash}/>
      </div>
      <div className={'main'}>
        {props.MainPageComponent}
      </div>
    </StyledWrapper>
  )
};

export default BaseClientPage;