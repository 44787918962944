import React from "react";
import styled from "styled-components";
import {palette} from "@stats/playbook-components";

const StyledLoading = styled.div`
  width: 100vw;
  background: ${palette["sp-light-gray-75"]};
`

export const Loading: React.FC = () => {
    return <StyledLoading title={'loading'}/>
}