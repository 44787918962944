import React from "react";
import ClientCatalogue from "./ClientCatalogue/ClientCatalogue";
import styled from "styled-components";
import { palette } from "@stats/playbook-components";

const StyledPageWrapper = styled.div`
  padding: 32px;
  background-color: ${palette["sp-light-gray-50"]};
  height: 100vh;
`;

const UserManagementDashboard: React.FC = () => {
  return (
    <StyledPageWrapper>
      <ClientCatalogue pageSize={20} />
    </StyledPageWrapper>
  );
};

export default UserManagementDashboard;
